@charset "UTF-8";

.detail_main {
    height: 700px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.detail_main_faq {
    background-image: url(../img/faq/faq_main.jpg);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.detail_main_shop {
    background-image: url(../img/shop/shop_main.jpg);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.detail_main_contact {
    background-image: url(../img/contact/contact_main.jpg);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}
