@charset "UTF-8";

.menu {
    position: absolute;
    left: 0px;
    top: 0;
    background-color: #16549b;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 96px;
    height: 96px;
    z-index: 1000;

    &:hover {
        cursor: pointer;
        background-color: #103f78;
    }

    div {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        span {
            display: inline-block;
            width: 34px;
            height: 14px;
            position: relative;

            i {
                transition: all 0.4s;
                display: inline-block;
                position: absolute;
                width: 34px;
                height: 2px;
                background-color: #fff;

                &:nth-child(1) {
                    top: 0;
                    left: 0;
                }

                &:nth-child(2) {
                    top: 6px;
                    left: 0;
                }

                &:nth-child(3) {
                    top: 12px;
                    left: 0;
                }
            }

        }

        p {
            color: #fff;
            @include fontHira;
            @include fontsize(12);
        }

    }
}

.menu_closed {
    div {
        span {
            i {
                &:nth-child(1) {
                    animation: menu_i_1 0.2s cubic-bezier(0.215, 0.61, 0.355, 1) 0s forwards;
                }

                &:nth-child(2) {
                    animation: menu_i_2 0.2s cubic-bezier(0.215, 0.61, 0.355, 1) 0s forwards;
                }

                &:nth-child(3) {
                    animation: menu_i_3 0.2s cubic-bezier(0.215, 0.61, 0.355, 1) 0s forwards;
                }
            }
        }
    }
}


@keyframes menu_i_1 {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(45deg);
        top: 0px;
    }
}

@keyframes menu_i_2 {
    0% {
        top: 12px;
        transform: rotate(0deg);
    }

    50% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(90deg);
        background-color: transparent;
    }
}

@keyframes menu_i_3 {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(135deg);
        top: 0px;
    }
}

.g_nav {
    background-color: #16549b;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    transform: translateX(-100%);
    z-index: 998;

    ul {
        li {
            a {
                span {
                    display: block;
                    @include fontsize(36);
                    font-weight: bold;
                    margin-top: 30px;
                    margin-bottom: 30px;
                }

                &:hover {
                    span {
                        opacity: 0.6;
                    }
                }
            }
        }
    }

    a {
        color: #fff;
    }

    .inner {
        margin-top: 150px;
    }
}

.g_nav_open {
    animation: slide_menu 0.4s cubic-bezier(0.215, 0.61, 0.355, 1) 0s forwards;
}

.g_nav_close {
    animation: slide_menu_close 0.4s cubic-bezier(0.215, 0.61, 0.355, 1) 0s forwards;
}

@keyframes slide_menu {
    0% {
        transform: translateX(-100%);
    }

    100% {
        transform: translateX(0);
    }
}

@keyframes slide_menu_close {
    0% {
        transform: translateX(0);
    }

    100% {
        transform: translateX(-100%);
    }
}
