@charset "UTF-8";

.shop01 {
    padding: 60px 0;
    display: flex;
    justify-content: center;

    .inner {
        max-width: 1000px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        @include res($res1024) {
            flex-direction: column;
            align-items: center;
            padding-left: 30px;
            padding-right: 30px;
            box-sizing: border-box;
        }

        .box {
            width: calc(50% - 20px);
            border: solid 1px #d2d2d2;
            padding: 40px;
            box-sizing: border-box;

            @include res($res1024) {
                width: 100%;
                max-width: 600px;

                &:first-child {
                    margin-bottom: 30px;
                }
            }

            dl {
                text-align: left;

                dt {
                    @include fontsize(20);
                    font-weight: bold;
                    margin-bottom: 20px;
                }

            }
        }
    }
}

.shop_calender {
    background-color: #effbff;
}

.shop02 {
    .inner {
        .title_parts01 {
            margin-bottom: 70px;
        }

        .inner02 {
            justify-content: space-between;
            display: flex;

            @include res($res1024) {
                flex-direction: column;
                align-items: center;
            }

            .box {
                width: calc(50% - 20px);

                @include res($res1024) {
                    width: 100%;
                    max-width: 600px;

                    &:first-child {
                        margin-bottom: 30px;
                    }
                }

                p {
                    width: 100%;
                    margin-bottom: 40px;

                    img {
                        width: 100%;
                    }
                }

                dl {
                    text-align: left;

                    dt {
                        @include fontsize(20);
                        font-weight: bold;
                        margin-bottom: 15px;
                    }

                }
            }
        }

        //inner02
    }
}
