@charset "UTF-8";
.title_parts01{
  text-align: center;
  display: block;
  color:#333;
  font-weight: bold;
  line-height: 1.2;
  span{
    @include fontsize(16);
    display: block;
    @include fontDe;
    margin-bottom: 17px;
  }
  h2{
    @include fontsize(36);
    position: relative;
    @include fontMe;
    &:after{
      display: block;
      content:"";
      width: 60px;
      height: 2px;
      background-color: #16549b;
      left: 0;
      right: 0;
      bottom: -25px;
      position: absolute;
      margin: auto;
    }
  }
}

.title_parts01_detail_main{
  text-align: center;
  display: block;
  color:#fff;
  font-weight: bold;
  line-height: 1.2;
  span{
    @include fontsize(16);
    display: block;
    @include fontDe;
    margin-bottom: 17px;
  }
  h1{
    @include fontsize(36);
    position: relative;
    @include fontMe;
    margin-bottom: 50px;
    &:after{
      display: block;
      content:"";
      width: 60px;
      height: 2px;
      background-color: #fff;
      left: 0;
      right: 0;
      bottom: -25px;
      position: absolute;
      margin: auto;

    }
  }
  p{
    line-height: 1.75;
  }
}

.title_parts01_left_white{
  text-align: left;
  display: block;
  color:#fff;
  font-weight: bold;
  line-height: 1.2;
  span{
    @include fontsize(16);
    display: block;
    @include fontDe;
    margin-bottom: 17px;
  }
  h2{
    @include fontsize(36);
    position: relative;
    @include fontMe;
    &:after{
      display: block;
      content:"";
      width: 60px;
      height: 2px;
      background-color: #fff;
      left: 0;
      bottom: -25px;
      position: absolute;
      margin: auto;
    }
  }
}

.title_parts01_left_black{
  text-align: left;
  display: block;
  color:#333;
  font-weight: bold;
  line-height: 1.2;
  span{
    @include fontsize(16);
    display: block;
    @include fontDe;
    margin-bottom: 17px;
  }
  h2{
    @include fontsize(36);
    position: relative;
    @include fontMe;
    &:after{
      display: block;
      content:"";
      width: 60px;
      height: 2px;
      background-color: #333;
      left: 0;
      bottom: -25px;
      position: absolute;
      margin: auto;
    }
  }
}

.title_parts01_white{
  text-align: center;
  display: block;
  color:#fff;
  font-weight: bold;
  line-height: 1.2;
  span{
    @include fontsize(16);
    display: block;
    @include fontDe;
    margin-bottom: 17px;
  }
  h2{
    @include fontsize(36);
    position: relative;
    @include fontMe;
    &:after{
      display: block;
      content:"";
      width: 60px;
      height: 2px;
      background-color: #fff;
      left: 0;
      right: 0;
      bottom: -25px;
      position: absolute;
      margin: auto;
    }
  }
}

.txt{
  line-height: 2.25;
  color:#333;
}

.btn_out{
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-top: 20px;
  a{
    display: block;
    width: 260px;
    height: 56px;
    border: solid 3px #3674b7;
    color:#252525;
    line-height: 56px;
    background-color: #fff;
    border-radius: 4px;
    font-weight: bold;
    &:hover{
      background-color: #3674b7;
      color:#fff;
    }

  }
}

.section_padding{
  padding-top: 120px;
  padding-bottom: 120px;
  display: flex;
  justify-content: center;
  @include res($res1024){
    padding-top: 60px;
    padding-bottom: 60px;
    box-sizing: border-box;
    padding-left: 30px;
    padding-right: 30px;
  }
  .inner{
    max-width: 1000px;
    width: 100%;
  }
}

.br_500 {
    display: none;
    @include res($res500){
        display: block;
    }
}
.br_414 {
    display: none;
    @include res($iphone7plus){
        display: block;
    }
}
