@charset "UTF-8";


@font-face {
  font-family: 'NotoSansCJKjp-Black';
  /*01*/
  src: url("font/NotoSansCJKjp-Black.woff");
}
@font-face {
  font-family: 'NotoSansCJKjp-Bold';
  /*02*/
  src: url("font/NotoSansCJKjp-Bold.woff");
}
@font-face {
  font-family: 'NotoSansCJKjp-DemiLight';
  /*03*/
  src: url("font/NotoSansCJKjp-DemiLight.woff");
}
@font-face {
  font-family: 'NotoSansCJKjp-Light';
  /*04*/
  src: url("font/NotoSansCJKjp-Light.woff");
}
@font-face {
  font-family: 'NotoSansCJKjp-Medium';
  /*05*/
  src: url("font/NotoSansCJKjp-Medium.woff");
}
@font-face {
  font-family: 'NotoSansCJKjp-Regular';
  /*06*/
  src: url("font/NotoSansCJKjp-Regular.woff");
}
@font-face {
  font-family: 'NotoSansCJKjp-Thin';
  /*07*/
  src: url("font/NotoSansCJKjp-Thin.woff");
}