@charset "UTF-8";

html {
	@include fontHira;
	font-size: 16px;
	margin: 0;
	padding: 0;
}

body {
	color: #333;
	line-height: 1.6;
	text-align: center;
	z-index: 0;
	word-break: break-all;
	z-index: 0;
	overflow-x: hidden;
	-webkit-font-smoothing: antialiased;
	font-smoothing: antialiased;
}

address,
body,
brockquote,
caption,
dd,
div,
dl,
dt,
fieldset,
form,
h1,
h2,
h3,
h4,
h5,
h6,
legend,
li,
object,
ol,
p,
pre,
table,
td,
th,
ul {
	margin: 0;
	padding: 0;
	font-weight: normal;
}

h1,
h2,
h3,
h4,
h5 {
	margin: 0;
}
////////////　フォ－ム
input[type="text"],
input[type="password"],
input[type="email"],
input[type="tel"] {
	font-family: 'メイリオ',Meiryo,'ヒラギノ角ゴ Pro W3','Hiragino Kaku Gothic Pro',sans-serif;
}

li {
	list-style: none;
}

a {
	transition: all 0.1s;
	-webkit-transform: translatez(0);
	color: #333;
}

a:active,
a:hover,
a:link,
a:visited {
	outline: none;
	transition: all 0.1s;
	color: #333;
	text-decoration: none;
}

a:hover{
	cursor: pointer;
}

a:hover img {
	opacity: 0.8;
	cursor: pointer;
}

a,
ins {
	text-decoration: none;
}

address,
cite,
dfn,
em,
var {
	font-style: normal;
}

input,
textarea {
	font-family: sans-serif;
	font-weight: normal;
}

img {
	vertical-align: bottom;
}

a img {
	border: none;
}

div img {
	width: 100%;
	height: auto;
}

p img {
	width: 100%;
	height: auto;
}

input[type="submit"] {
   -webkit-appearance: none;
   border-radius: 0;
}
