@charset "UTF-8";

.g_header {
    height: 95px;
    background-color: transparent;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    width: 100%;

    .wrap {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        background-color: transparent;

        .logo {
            a {
                display: block;
                color: #fff;

                span {
                    @include fontPop;
                    @include fontsize(28);
                    letter-spacing: 0.02em;

                    @include res($res600) {
                        @include fontsize(18);
                    }
                }

                .logo_title {
                    @include fontsize(14);
                }
            }
        }

        .phone {
            position: absolute;
            right: 40px;
            top: 0;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            color: #fff;

            @include res($res1024) {
                display: none;
            }

            p {
                @include fontsize(16);
            }

            span {
                display: block;
                @include fontPop;
                @include fontsize(24);

            }
        }

        .sp_phone {
            display: none;

            @include res($res1024) {
                display: block;
                position: absolute;
                right: 20px;
                top: 27px;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                width: 44px;
                height: 44px;
                background-color: #16549b;
                border-radius: 22px;

                a {
                    display: block;
                    width: 25px;
                }

            }
        }
    }
}

.header_fixed {
    .wrap {
        .logo {
            a {
                color: #333;
            }
        }

        .phone {
            color: #333;
        }
    }
}
