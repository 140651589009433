@charset "UTF-8";

.top_main {
    width: 100%;
    height: 100vh;

    .slider {
        width: 100%;
        height: 100vh;
        margin-bottom: 0;

        .main_img {
            width: 100%;
            height: 100vh;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            background-image: url(../img/top/main_img.jpg);
            background-size: cover;
            background-position: top center;
            position: relative;

            h2 {
                span {
                    display: block;
                    color: #fff;
                    @include fontsize(48);
                    @include fontBo;

                    @include res($res1024) {
                        @include fontsize(24);
                    }

                    @include res($res400) {
                        @include fontsize(18);
                    }
                }
            }
        }

        .main_img2 {
            background-image: url(../img/top/main_img2.jpg);
        }

        .main_img3 {
            background-image: url(../img/top/main_img3.jpg);
        }

        .slick-dots {
            position: absolute;
            right: 50px;
            bottom: 30px;
            display: inline-block !important;
            width: auto;

            @include res($res1024) {
                display: none !important;
            }

            li {
                width: 16px;
                height: 16px;
                margin-left: 10px;
                margin-right: 10px;

                button {
                    display: block;
                    background-color: #fff;
                    text-indent: -9999999px;
                    width: 16px;
                    height: 16px;
                    border-radius: 8px;

                    &:hover {
                        cursor: pointer;
                    }
                }
            }

            .slick-active {
                button {
                    background-color: #16549b;
                }
            }

        }
    }

    .scroll_down {
        position: absolute;
        bottom: 30px;
        @include fontsize(18);
        left: 0;
        right: 0;
        margin: auto;
        font-weight: bold;
        @include fontPop;
        display: inline-block;
        width: 150px;

        p {
            color: #fff;
            margin-bottom: 15px;
        }

        div {
            display: inline-block;

            img {
                width: 60px;
            }
        }
    }
}

.top_news {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding-top: 120px;
    padding-bottom: 105px;

    @include res($res1024) {
        padding-left: 15px;
        padding-right: 15px;
        box-sizing: border-box;
    }

    .inner {
        max-width: 1000px;
        display: flex;
        flex-direction: row;
        justify-content: center;

        @include res($res1024) {
            flex-direction: column;
            align-items: center;
        }

        .left {
            width: 240px;
            display: flex;
            justify-content: flex-start;

            @include res($res1024) {
                width: 100%;
                justify-content: center;

                .title_parts01 {
                    margin-bottom: 80px;
                }
            }
        }

        .right {
            width: calc(100% - 240px);
            text-align: left;

            @include res($res1024) {
                width: 100%;
                max-width: 600px;
            }

            ul {
                li {
                    margin-bottom: 24px;
                    padding-left: 15px;
                    box-sizing: border-box;

                    a {
                        span {
                            line-height: 1.2;
                            color: #666;
                        }
                    }
                }
            }
        }
    }
}

.top_concept {
    display: flex;
    flex-direction: row;
    position: relative;
    margin-bottom: 400px;

    @include res($res1200) {
        margin-bottom: 500px;
    }

    @include res($res1024) {
        flex-direction: column;
        margin-bottom: 0;
    }

    .left,
    .right {
        width: calc(50% + 100px);

        @include res($res1024) {
            width: 100%;
        }
    }

    .left {
        div {
            img {
                width: 100%;
            }
        }
    }

    .right {
        background: linear-gradient(45deg, rgba(22, 131, 255, 1) 0%, rgba(141, 194, 255, 1) 100%);
        color: #fff;
        position: absolute;
        right: 0;
        top: 100px;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 800px;

        @include res($res1024) {
            position: static;
            padding-top: 80px;
            padding-bottom: 80px;
            height: auto;

        }

        .right_inner {
            text-align: left;
            max-width: 500px;

            @include res($res1024) {
                padding-left: 30px;
                padding-right: 30px;
                box-sizing: border-box;
                max-width: 600px;
            }

            .title_parts01_left_white {
                margin-bottom: 50px;
            }

            p {
                line-height: 2.25;
            }
        }
    }
}

.top_faq {
    background-image: url(../img/top/back01.jpg);
    background-size: 100%;
    background-position: top center;

    .inner {
        .title_parts01_white {
            margin-bottom: 50px;
        }

        .txt {
            margin-bottom: 32px;
            color: #fff;
        }

        .top_faq_content {
            width: 100%;
            margin-bottom: 30px;
            background-color: #0063d7;

            dt {
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                padding-top: 12px;
                padding-bottom: 12px;
                color: #fff;
                box-sizing: border-box;

                .q {
                    display: flex;
                    justify-content: center;
                    width: 70px;
                    @include fontPop;
                    @include fontsize(40);

                    i {
                        display: block;
                    }
                }

                p {
                    width: calc(100% - 144px);
                    text-align: left;
                }

                span {
                    display: block;
                    width: 34px;
                    height: 34px;
                    position: relative;

                    i {
                        display: block;
                        width: 34px;
                        height: 3px;
                        background-color: #fff;
                        position: absolute;
                        top: 16px;

                        &:nth-child(2) {
                            transform: rotate(90deg);

                        }
                    }
                }

                &:hover {
                    cursor: pointer;
                }
            }

            //dt
            .faq_active {

                span {
                    i {
                        &:nth-child(2) {
                            display: none;
                        }
                    }
                }

            }

            dd {
                text-align: left;
                padding-left: 40px;
                padding-right: 40px;
                box-sizing: border-box;
                padding-bottom: 40px;
                box-sizing: border-box;
                color: #fff;
                padding-top: 10px;
                display: none;
            }

            /*&:hover{
        background-color: #16549b;
      }*/
        }

    }
}

.top_item {
    background-color: #effbff;

    .inner {
        .title_parts01 {
            margin-bottom: 50px;
        }

        .txt {
            margin-bottom: 32px;
        }

        .inner02 {
            display: flex;
            flex-direction: row;
            justify-content: space-between;

            @include res($res800) {
                flex-direction: column;
                justify-content: center;
                align-items: center;
            }

            .box {
                width: calc(50% - 20px);

                @include res($res800) {
                    width: 100%;
                    max-width: 500px;
                    margin-bottom: 30px;
                }

                p {
                    margin-bottom: 25px;

                    img {
                        width: 100%;
                    }
                }

                dl {
                    text-align: left;

                    dt {
                        @include fontsize(18);
                        margin-bottom: 5px;
                    }

                    dd {
                        &:nth-child(2) {
                            margin-bottom: 4px;
                        }

                        &:nth-child(3) {
                            @include fontsize(20);
                        }
                    }
                }
            }
        }
    }
}

.top_blog {
    .inner {
        .title_parts01 {
            margin-bottom: 80px;
        }

        .inner02 {
            display: flex;
            flex-direction: row;
            justify-content: space-between;

            @include res($res800) {
                flex-direction: column;
                justify-content: center;
                align-items: center;
            }

            .box {
                display: block;
                width: calc(33.333333% - 20px);

                @include res($res800) {
                    width: 100%;
                    max-width: 500px;
                    margin-bottom: 30px;
                }

                &:hover {
                    opacity: 0.6;
                }

                p {
                    position: relative;
                    width: 100%;
                    height: 313px;
                    background-size: cover;
                    background-position: center;

                    span {
                        display: inline-block;
                        position: absolute;
                        left: 15px;
                        bottom: 15px;
                        background-color: #6abce9;
                        color: #fff;
                        @include fontsize(14);
                        line-height: 24px;
                        padding: 0 12px;
                    }
                }

                dl {
                    text-align: left;
                    padding: 30px;
                    box-sizing: border-box;
                    background-color: #effbff;

                    dt {
                        @include fontsize(18);
                        margin-bottom: 5px;
                    }

                    dd {
                        &:nth-child(2) {
                            margin-bottom: 4px;
                        }

                        &:nth-child(3) {
                            @include fontsize(20);
                        }
                    }
                }
            }
        }

        .btn_out {
            padding-top: 50px;
        }
    }
}

.top_shop {
    .innerv2 {
        display: flex;
        width: 100%;

        @include res($res1024) {
            flex-direction: column;

        }

        .left,
        .right {
            width: 50%;

            @include res($res1024) {
                width: 100%;
            }
        }

        .left {
            background-image: url(../img/top/back02.jpg);
            background-size: cover;
            background-position: top center;
            background-repeat: no-repeat;

            @include res($res1024) {
                height: 600px;
            }
        }

        .right {
            background-color: #effbff;
            padding-top: 150px;
            padding-bottom: 150px;
            box-sizing: border-box;
            padding-left: 120px;

            @include res($res1024) {
                padding-top: 60px;
                padding-bottom: 60px;
                padding-left: 0;
                display: flex;
                flex-direction: row;
                justify-content: center;
                padding-left: 30px;
                padding-right: 30px;
                box-sizing: border-box;
            }

            .right_inner {
                @include res($res1024) {
                    max-width: 600px;
                }

                .title_parts01_left_black {
                    margin-bottom: 60px;
                }

                .table_out {
                    table {
                        tr {

                            th,
                            td {
                                text-align: left;
                                padding-bottom: 20px;
                            }
                        }
                    }
                }

                //table_out
                .btn_out {
                    text-align: left;
                    justify-content: flex-start;

                    a {
                        text-align: center;
                    }
                }
            }
        }
    }
}

.top_calender {
    .title_parts01 {
        margin-bottom: 70px;
    }

    .cl_out {
        @include res($res1024) {
            display: none;
        }
    }

    .cl_out02 {
        display: none;

        @include res($res1024) {
            display: block;
        }
    }
}


.top_blog_contents {
    p {
        a {
            text-decoration: underline;
            color: RGBA(51, 86, 145, 1.00);

            &:hover {
                text-decoration: none;
            }
        }
    }
}
