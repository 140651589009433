@charset "UTF-8";

@import url('https://fonts.googleapis.com/css?family=Poppins:700');

@import "settings/value";
@import "settings/reset";
@import "settings/part";
@import "layout/header";
@import "layout/footer";
@import "layout/menu";
@import "layout/index";
@import "layout/detail_main";
@import "layout/shop";
@import "layout/contact";
@import "layout/faq";
