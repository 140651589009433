@charset "UTF-8";

.footer {
    background-color: #21466d;
    position: relative;

    .copy {
        color: #fff;
        line-height: 92px;
    }

    .pagetop {
        position: absolute;
        right: 0;
        top: 0;
        width: 92px;
        height: 92px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;

        span {
            display: block;
            width: 50px;

            img {
                width: 100%;
            }
        }
    }
}
