@charset "UTF-8";

.contact_txt_out {
    padding-top: 88px;
    padding-bottom: 40px;

    @include res($res1024) {
        padding-left: 30px;
        padding-right: 30px;
        box-sizing: border-box;
    }

    .inner {
        text-align: center;

        @include res($res1024) {
            max-width: 600px;

        }

        dl {
            dt {
                line-height: 1.8;
                @include fontsize(20);
                margin-bottom: 15px;

                @include res($res1024) {
                    @include fontsize(16);
                }
            }

            dd {
                line-height: 1.2;
                @include fontsize(36);
                @include fontPop;
                font-weight: bold;

                @include res($res1024) {
                    @include fontsize(20);
                }
            }
        }
    }
}

.contact_form_out {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding-bottom: 100px;

    @include res($res1024) {
        padding-left: 30px;
        padding-right: 30px;
        box-sizing: border-box;
        align-items: center;
    }

    .inner {
        max-width: 1000px;
        width: 100%;

        @include res($res1024) {
            max-width: 600px;
        }

        .form_box {
            margin-bottom: 40px;
            width: 100%;
            text-align: left;

            label {
                width: 100%;
                display: block;
                @include fontsize(16);
                margin-bottom: 7px;
                line-height: 26px;

                .txt {
                    margin-right: 10px;
                }

                .required_txt {
                    color: #fff;
                    background-color: #ed9125;
                    display: inline-block;
                    padding-left: 15px;
                    padding-right: 15px;
                    box-sizing: border-box;
                }
            }

            .form01 {
                width: 100%;
                display: block;
                @include fontsize(16);
                border: solid 1px #d2d2d2;
                height: 62px;
                padding-left: 15px;
                padding-right: 15px;
                box-sizing: border-box;
            }

            .form02 {
                min-height: 250px;
                height: auto;
            }
        }

        //form_box
        .pp_box {
            h3 {
                @include fontsize(20);
                margin-bottom: 15px;
            }

            .pp_box02 {
                border: solid 1px #5e5e5e;
                padding: 30px;
                height: 180px;
                overflow-y: scroll;
                margin-bottom: 20px;

                .pp_inner {
                    text-align: left;
                }
            }
        }
    }
}

.submit_btn_out {
    padding-top: 40px;

    input {
        width: 260px;
        height: 56px;
        line-height: 56px;
        background-color: #ddd;
        color: #999;
        @include fontsize(16);
        border: none;
        font-weight: bold;
    }

    .submit_active {
        background-color: #16549b;
        color: #fff;
        cursor: pointer;

        &:hover {
            background-color: #103f78;
        }

    }
}
