@charset "UTF-8";

.faq_content01 {
    background-color: #effbff;
    padding-top: 90px;
    padding-bottom: 90px;

    @include res($res1024) {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .inner {
        @include res($res1024) {
            max-width: 600px;
        }

        .txt01 {
            line-height: 1.8;
            margin-bottom: 55px;
        }

        .faq_box01 {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            width: 100%;
            text-align: left;
            margin-bottom: 43px;

            @include res($res1024) {
                flex-direction: column;
            }

            .left,
            .right {
                width: calc(50% - 20px);

                @include res($res1024) {
                    width: 100%;
                }
            }

            .left {
                img {
                    width: 100%;
                }

                @include res($res1024) {
                    margin-bottom: 30px;
                }
            }

            .right {
                p {
                    @include fontsize(18);
                    margin-bottom: 25px;
                }

                ul {
                    li {
                        @include fontsize(20);
                    }
                }
            }
        }

        .faq_box02 {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            width: 100%;
            text-align: left;
            flex-wrap: wrap;

            @include res($res1024) {
                flex-direction: column;
            }

            dl {
                width: calc(50% - 20px);
                margin-bottom: 28px;

                @include res($res1024) {
                    width: 100%;
                }

                dt {
                    @include fontsize(20);
                    margin-bottom: 12px;
                }

            }
        }
    }
}

.faq_content02 {
    padding-top: 90px;
    padding-bottom: 90px;

    @include res($res1024) {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .inner {
        @include res($res1024) {
            max-width: 600px;
        }

        .faq_box03 {
            text-align: left;

            p {
                margin-bottom: 30px;
                line-height: 1.8;
            }
        }
    }
}

.faq_h2 {
    display: flex;
    flex-direction: row;
    align-items: center;
    text-align: left;
    margin-bottom: 27px;

    span {
        @include fontsize(40);
        @include fontPop;
        font-weight: bold;
        margin-right: 15px;

        @include res($res1024) {
            @include fontsize(28);
        }
    }

    p {
        @include fontsize(24);
        margin-bottom: 0;

        @include res($res1024) {
            @include fontsize(18);
        }
    }
}

.faq_content03 {
    padding-top: 90px;
    padding-bottom: 90px;
    background-color: #effbff;

    @include res($res1024) {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .inner {
        text-align: left;

        @include res($res1024) {
            max-width: 600px;
        }

        .faq_h202 {
            text-align: center;
            @include fontsize(28);
            font-weight: bold;
            margin-bottom: 34px;
        }

        .faq_pic01 {
            margin-bottom: 34px;

            img {
                width: 100%;
            }
        }

        .faq_box03 {
            margin-bottom: 26px;

            .faq_h2 {
                margin-bottom: 17px;
            }

        }
    }
}

.faq_content04 {
    background-color: #fff;
}
